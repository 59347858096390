import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import ClideLoader from "app/shared/ui-kit/loader";
import axios from "axios";
import Url from "app/shared/constants/Url";
import { Row, Col, Modal } from "react-bootstrap";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import { Edit2 } from "react-feather";
import { useTranslation } from "react-i18next";
const AIModal = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [showRemarkOptions, setShowRemarkOptions] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState("");
  const [remarkText, setRemarkText] = useState("");
  const [loadingRemark, setLoadingRemark] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { t } = useTranslation(['aialert', 'util']);
  const remarkMapping = {
    approve: t('approve'),
    partially_correct: t('partiallyCorrect'),
    reject: t('reject'),
  };

  useEffect(() => {
  }, [props.aiData]);

  const handleRemarkClick = () => {
    setShowRemarkOptions(true);
    setSelectedRemark(props.aiData.aiRemark || "");
  };
  const handleRemarkSelection = (e) => {
    setSelectedRemark(e.target.value);
  };

  const handleRemarkTextChange = (e) => {
    setRemarkText(e.target.value);
  };

  const submitRemark = () => {
    if (!selectedRemark || !remarkText) {
      setAlertMessage(t('selectRemarkEnterCommentBeforeSubmitting'));
      setAlertType("error");
      return;
    }

    setLoadingRemark(true);
    setDataLoading(true);

    const ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let requestData = new FormData();
    requestData.append("type", props.type == "uauc" ? "uauc" : "AiViolation");
    requestData.append("ai_remark", selectedRemark);
    requestData.append("comment", remarkText);
    requestData.append("id", props.aiData.id);

    axios
      .post(Url.adminServices.aialert.aiRemark, requestData, { headers: ContentType })
      .then((response) => {
        console.log("API Response:", response.data);
        if (response.data.status === 200) {
          setShowRemarkOptions(false);
          setAlertType("success");
          props.handleClose();
          AlertMessage({
            message: t('remarkSubmittedSuccessfully'),
            type: "success",
          });
        } else {
          setAlertType("error");
          AlertMessage({
            message: t('failedToSubmitRemarkTryAgain'),
            type: "error",
          });
        }
        setLoadingRemark(false);
        setDataLoading(false);
      })
      .catch((error) => {
        console.error("Error adding remark:", error);
        setAlertType("error");
        setLoadingRemark(false);
        setDataLoading(false);
        AlertMessage({
          message: t('failedToSubmitRemarkTryAgain'),
          type: "error",
        });
      });
  };

  const isAiViolationResolve = props.type === "AiViolationResolve";

  return (
    <div className="modal-xl" dialogClassName="modal-90w">
      <Modal.Header closeButton style={{ alignItems: "center" }}>
        <Modal.Title style={{ textAlign: "center", flex: "auto" }}>
          {t('aiVerificationReport')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pr-0">
        {alertMessage && (
          <div
            className={`alert ${alertType === "success" ? "alert-success" : "alert-danger"
              }`}
            role="alert"
          >
            {alertMessage}
          </div>
        )}
        {dataLoading ? (
          <div className="my-3">
            <ClideLoader />
          </div>
        ) : (
          <Scrollbars
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100%"
            renderTrackHorizontal={(props) => (
              <div {...props} className="track-horizontal" style={{ display: "none" }} />
            )}
            renderThumbHorizontal={(props) => (
              <div {...props} className="thumb-horizontal" style={{ display: "none" }} />
            )}
            renderView={(props) => (
              <div
                style={{
                  paddingRight: "15px",
                  ...props.style,
                  overflowX: "hidden",
                }}
              />
            )}
          >
            <div className="m-2">
              <div className="d-flex">
                <div className="clide-v2-white-box p-3 m-2" style={{ maxWidth: "35rem" }}>
                  <img
                    src={props.aiData.aiImage || props.aiData.imageLink}
                    alt={t('aiReport')}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
                {isAiViolationResolve && (props.aiData.evidence.resolveImageUrl || props.aiData.evidence.imageUrl) && (
                  <div className="clide-v2-white-box p-3 m-2" style={{ maxWidth: "35rem" }}>
                    <img
                      src={props.aiData.evidence.resolveImageUrl || props.aiData.evidence.imageUrl}
                      alt={t('additionalContent')}
                      style={{ maxWidth: "100%", height: "auto", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>
              <div className="mt-3 d-flex">
                <Col md={(isAiViolationResolve && props.aiData.evidence.resolveMsg) ? 6 : 12}>
                <Row className="text-left ">
                  {Object.entries(props.aiData.aiResponse || {}).map(([key, value]) => (
                    <Col key={key} md={6} className="p-0 mb-2">
                      <span className="font-weight-bold mr-1">
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                      </span>
                      <span style={{ color: value ? "green" : "red" }}>
                        {value ? t('detected') : t('notDetected')}
                      </span>
                    </Col>
                  ))}
                </Row>
                </Col>
                <Col>
                {isAiViolationResolve && props.aiData.evidence.resolveImageUrl && (
                  <Row className="text-left ">
                    {Object.entries(props.aiData.aiResolveResponse || {}).map(([key, value]) => (
                      <Col key={key} md={6} className="p-0 mb-2">
                        <span className="font-weight-bold mr-1">
                          {key.charAt(0).toUpperCase() + key.slice(1)}:
                        </span>
                        <span style={{ color: value ? "green" : "red" }}>
                          {value ? t('detected') : t('notDetected')}
                        </span>
                      </Col>
                    ))}
                  </Row>
                )}
                </Col>
              </div>


              <div className="mt-3 d-flex">
                {props.aiData.aiRemark && (
                  <Col md={(isAiViolationResolve && props.aiData.evidence.resolveMsg) ? 6 : 12} className="d-flex p-0">
                    <div className="">
                      <div className="clide-v2-colume_title">
                        {t('submittedRemark')}:
                        <span style={{ color: "#F56620" }}> {props.aiData.aiRemark}</span>
                      </div>
                      {props.aiData.comment && (
                        <div className="clide-v2-colume_title">
                          {t('comment')}:
                          <span style={{ color: "#F56620" }}> {props.aiData.comment}</span>
                        </div>
                      )}
                    </div>
                    <>
                      {!isAiViolationResolve && (
                        <div
                          className="pr-1 d-flex align-items-center ml-auto"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <button
                            className="trash btn p-1"
                            style={{ border: "2px solid #70c23e", color: "#70c23e" }}
                            onClick={() => {
                              handleRemarkClick();
                              setSelectedRemark(props.aiData.aiRemark);
                              setRemarkText(props.aiData.comment || "");
                            }}
                            title={t('editRemark')}
                          >
                            <Edit2 style={{ width: "20px", height: "20px" }} />
                          </button>
                        </div>
                      )}
                    </>
                  </Col>
                )}
                {isAiViolationResolve && props.aiData.evidence.resolveMsg && (
                  <Col md={6} className="p-0">
                    <div className="clide-v2-colume_title">
                      {t('closingRemark')}:
                      <span style={{ color: "#F56620" }}> {props.aiData.evidence.resolveMsg}</span>
                    </div>
                  </Col>
                )}
              </div>



              {!props.aiData.aiRemark && !showRemarkOptions && (
                <div className="text-center my-2">
                  <button
                    className="clide-v2-border-primary-color border rounded clide-v2-fs-5 px-3 py-1 clide-v2-active"
                    onClick={handleRemarkClick}
                  >
                    {t('aiRemark')}
                  </button>
                </div>
              )}
              {showRemarkOptions && (
                <div className="mt-3 px-3">
                  <div className="text-center">
                    <div className="clide-v2-colume_title">{t('selectRemark')}</div>
                  </div>
                  <div className="d-flex space-between align-items-center mt-2">
                    {Object.entries(remarkMapping).map(([value, displayName]) => (
                      <label key={value} className="mb-2 d-flex align-items-center">
                        <input
                          type="radio"
                          name="remark"
                          value={value}
                          onChange={handleRemarkSelection}
                          checked={selectedRemark === value || selectedRemark === displayName} // Ensure the correct radio button is selected
                          className="mr-2"
                        />
                        {displayName}
                      </label>
                    ))}
                  </div>

                  <div className="mt-3">
                    <input
                      id="remarkText"
                      type="text"
                      value={remarkText}
                      onChange={handleRemarkTextChange}
                      className="form-control"
                      placeholder={t('enterYourRemark')}
                    />
                  </div>

                  <div className="text-center mt-3">
                    <button
                      className="clide-v2-border-primary-color border rounded clide-v2-fs-5 px-3 py-1 clide-v2-active mr-2"
                      onClick={() => setShowRemarkOptions(false)}
                    >
                      {t('cancel')}
                    </button>
                    <button
                      className="clide-v2-border-primary-color border rounded clide-v2-fs-5 px-3 py-1 clide-v2-active"
                      onClick={submitRemark}
                      disabled={loadingRemark}
                    >
                      {loadingRemark ? t('submitting') : t('submit')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Scrollbars>
        )}
      </Modal.Body>
    </div>
  );
};

export default AIModal;